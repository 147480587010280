import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TemplateOne from './Templateone';
import TemplateThree from './TemplateThree';
import Template8 from './Template8';
import Template11 from './Template11';
import Template12 from './Template12';
import Template13 from './Template13';
import Template15 from './Template15';
import Template17 from './Template17';
import Temp18 from './Temp18';

const DynamicTemplateRenderer = () => {
  const [templateComponent, setTemplateComponent] = useState(null);
  const [userParam, setUserParam] = useState(new URLSearchParams(window.location.search).get("user"));

  useEffect(() => {
    if (!userParam) {
      console.error('No user parameter found in the URL search string.');
      return;
    }
    const fetchUserTheme = async () => {
      try {
        const response = await axios.get(`https://api.x-tag.tech/api/user/${userParam}`);
        const theme = response.data.data.theme;
        switch (theme) {
          case 2:
            setTemplateComponent(<TemplateOne />);
            break;
          case 3:
            setTemplateComponent(<TemplateThree />);
            break;
          case 5:
            setTemplateComponent(<Template8 />);
            break;
          // case 7:
          //   setTemplateComponent(<Template11 />);
          //   break;
          case 8:
            setTemplateComponent(<Template12 />);
            break;
            case 9:
            setTemplateComponent(<Template17 />);
            break;
            case 10:
            setTemplateComponent(<Template15 />);
            break;
          case 12:
            setTemplateComponent(<Template13 />);
            break;
            case 13:
            setTemplateComponent(<Template13 />);
            case 14:
              setTemplateComponent(<Temp18 />);
            break;
          default:
            console.error('Invalid theme or theme not handled:', theme);
            setTemplateComponent(<div>Theme not supported.</div>);
            break;
        }
      } catch (error) {
        console.error('Error fetching user theme:', error);
        setTemplateComponent(<div>Error loading the template. Please try again later.</div>);
      }
    };

    fetchUserTheme();
  }, [userParam]);

  return (
    <div>
      {templateComponent || <div></div>}
    </div>
  );
};

export default DynamicTemplateRenderer;
