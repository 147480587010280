import React,{useState,useEffect,useRef} from 'react'
import image from '../assets/person.jpg'
import Pdfi from '../assets/PDF-icon.png'
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { FaPlus } from "react-icons/fa";
import { IoShareSocial } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa";
import Modal from "@mui/material/Modal";
import qr from "../assets/pngwing.com (10).png";
import QRCode from "qrcode.react";
import share from "../assets/pngwing.com (9).png";
import copy from "../assets/pngwing.com (11).png";
import { RWebShare } from "react-web-share";
import { IoQrCodeOutline } from "react-icons/io5";
import { PiUploadSimpleBold } from "react-icons/pi";
import { Box, SpeedDial } from '@mui/material';
import { FaPhone } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import { FaTelegramPlane } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { pdfjs } from "react-pdf";
import { IoLogoWhatsapp } from "react-icons/io5";
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress"; 
import tinycolor from "tinycolor2";
import { BsFiletypePdf } from 'react-icons/bs';
import powerdByXTag from '../assets/poweredByXtag.png'
import powerdByXTagWhite from '../assets/poweredbyxtagWhite.png'

import powerdByXTagBlack from '../assets/poweredbyxtagBlack.png'

const Template13 = () => {
  const [oppen1, setOppen1] = React.useState(false);
  const [oppen, setOppen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userData, setUserData] = useState(null);
  const [urlToShare, seturlToShare] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state variable for loading status
  const [opennnn, setOpennnn] = useState(false);
  const handleCloseeee = () => setOpennnn(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const handleImageClick = () => {
    setModalOpen(true);
  };
  const [animate, setAnimate] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  let retryCount = 0;
  const maxRetryCount = 3; // Maximum number of retries
  const retryDelay = 2000; // Delay between retries in milliseconds
  const getCurrentUrl = () => {
    seturlToShare(window.location.href);
  };
  const retryFetch = () => {
    if (retryCount < maxRetryCount) {
      setTimeout(() => {
        fetchData();
        retryCount++;
      }, retryDelay);
    } else {
      console.error("Maximum retry attempts reached.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrentUrl();
  }, []);
  const opene = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const speedDialRef = useRef();
  const location = useLocation();

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const userParam = query.get("user");

  console.log("userParam", userParam);
 
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      try {
        const response = await axios.get(
          `https://api.x-tag.tech/api/user/${userParam}`
        );

        setUserData(response.data.data);
        console.log(response.data.data);
        document.documentElement.style.setProperty(
          "--bg-color",
          response.data.data.bgColor
        );
        document.documentElement.style.setProperty(
          "--button-bg-color",
          response.data.data.buttonColor
        );
        document.documentElement.style.setProperty(
          "--border-color",
          response.data.data.buttonColor
        );

        if (apicounter == 0) {
          axios.post(
            `https://api.x-tag.tech/api/profile/${response.data.data?.id}/visit`,
            {}
          );
          apicounter++;
          console.log(apicounter);
        }
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.error("Server responded with error:", error.response.status);

          setIsLoading(false);
        } else if (error.request) {
          console.error("No response received:", error.request);
          retryFetch();
        } else {
          console.error("Error setting up request:", error.message);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [userParam]);
  let apicounter = 0;

  const [textcolor, setTextColor] = useState("#ffffff");
  const [textbottoncolor, setTextBottonColor] = useState("#ffffff");

  useEffect(() => {
    const mainBackgroundColor = userData?.bgColor;

    if (mainBackgroundColor) {
      const brightness = tinycolor(mainBackgroundColor).getBrightness();

      setTextColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [userData?.bgColor]);

  useEffect(() => {
    const buttonsBackground = userData?.buttonColor;

    if (buttonsBackground) {
      const brightness = tinycolor(buttonsBackground).getBrightness();

      // Adjust text color based on brightness
      setTextBottonColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [userData?.buttonColor]);
  const saveAsPDF = async () => {
    const targetComponent = document.getElementById("mainComponent");
    if (targetComponent) {
      try {
        const canvas = await html2canvas(targetComponent);
        const imgData = canvas.toDataURL("image/png");

        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([canvas.width, canvas.height]);
        const { width, height } = page.getSize();
        const img = await pdfDoc.embedPng(imgData);
        const { width: imgWidth, height: imgHeight } = img.scale(1);

        page.drawImage(img, {
          x: 0,
          y: 0,
          width: imgWidth,
          height: imgHeight,
        });

        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], { type: "application/pdf" });

        // Use FileSaver.js library to save the blob as a file
        const saveAs = require("file-saver");
        saveAs(blob, "screenshot.pdf");
      } catch (error) {
        console.error("Error saving as PDF:", error);
      }
    }
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const params = new URLSearchParams(window.location.search);

  const profileValue = params.get("profile");

  console.log("Profile:", profileValue);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCopyLink = () => {
    const linkToCopy = urlToShare;

    // Create a temporary input element
    const tempInput = document.createElement("input");
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);

    tempInput.select();
    document.execCommand("copy");

    // Remove the temporary input
    document.body.removeChild(tempInput);
    // toast.success('Link copied!');

    console.log("Link copied to clipboard:", linkToCopy);
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 1000); // 5000 milliseconds (5 seconds)

    // You can add additional logic or feedback here if needed
  };

  const handleDocumentClick = (e) => {
    if (speedDialRef.current && !speedDialRef.current.contains(e.target)) {
      // Click occurred outside the SpeedDial, hide it
      setOppen(false);
    }
  };
  const toDataURL = (url) =>
    axios
      .get(url, {
        responseType: "blob", // Important to handle the response as a Blob
        headers: {},
      })
      .then((response) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(response.data);
        });
      })
      .catch((error) => {
        console.error("Error in toDataURL with axios:", error);
        return null;
      });

  const saveToContacts = async () => {
    const contact = {
      name: userData?.firstName + " " + userData?.lastName,
      mobile: userData?.phoneNum || "",
      email: userData?.email || "",
      address: userData?.location || "",
      organization: userData?.businessName || "",
      xTagWebsite: urlToShare,
      photo: userData?.photo || "",
      primary_links: userData?.primary_links || [],
    };

    let imageDataUrl = "";
    if (contact.photo !== "") {
      imageDataUrl = await toDataURL(contact.photo);
      console.log("imageDataUrl,imageDataUr", imageDataUrl);
      if (!imageDataUrl) {
        console.error("Failed to convert image to Data URL");
        return;
      }
    }

    let vcard =
      `BEGIN:VCARD\nVERSION:3.0\n` +
      `N:${contact.name};;;\n` +
      `FN:${contact.name}\n` +
      `TEL;CELL:${contact.mobile}\n` +
      `EMAIL;HOME:${contact.email}\n` +
      `ADR;HOME:${contact.address}\n` +
      `ORG;WORK:${contact.organization}\n` +
      `item1.URL:${contact.xTagWebsite}\n` +
      `item1.X-ABLabel:X TAG WEBSITE\n` +
      `PHOTO;ENCODING=b;TYPE=JPEG:${imageDataUrl.split(",")[1]}\n`;

    contact?.primary_links?.forEach((link, index) => {
      const itemNumber = index + 2; // Starting from 2 as 1 is used for xTagWebsite
      vcard +=
        `item${itemNumber}.URL:${link.value}\n` +
        `item${itemNumber}.X-ABLabel:${link.name}\n`;
    });

    vcard += `END:VCARD`;

    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    // Create a hidden download link and trigger it
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${contact.name}.vcf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleLinkClick = async (primaryItem) => {
    const linkId = primaryItem.id; // Assuming each item has an ID

    try {
      await axios.post(
        `https://api.x-tag.tech/api/link/${linkId}/visit`
      );
      console.log("API called for link:", linkId);
    } catch (error) {
      console.error("Error calling API for link:", linkId, error);
    }
  };
  const handlePrimaryLinkClick = async (primaryItem) => {
    const linkId = primaryItem.id; // Assuming each item has an ID
console.log('primaryItem',primaryItem)
    try {
      await axios.post(
        `https://api.x-tag.tech/api/${userData.id}/primary_link/${linkId}/visit`
      );
      console.log("API called for link:", linkId);
    } catch (error) {
      console.error("Error calling API for link:", linkId, error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress /> {/* Loading spinner */}
      </div>
    );
  }


  function hexToRgba(hex, opacity = 1) {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (hex?.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex?.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r},${g},${b},${opacity})`;
  }
  const startColor = hexToRgba(userData?.bgColor, 0); // Fully transparent
  const endColor = hexToRgba(userData?.bgColor, 1); // Fully opaque
  return (
    <div className="w-screen h-full" style={{backgroundColor: userData?.bgColor}}
    >
    <div className=' absolute top-0 bottom-0 left-0 right-0 -z-20 ' style={{backgroundColor: userData?.bgColor}}></div>
      <div
        style={{
          position: "relative",
          height: "60vh",
          width: "100%",
          overflow: "hidden",
        }}
      >
      <div
      className="fixed w-screen h-screen -z-10 "
      style={{ backgroundColor: userData?.bgColor }}
    ></div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${userData?.photo})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            zIndex: 0,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: `linear-gradient(to bottom, ${startColor}, ${endColor})`,
            zIndex: 1,
          }}
        >
          <div className="flex flex-col justify-end items-start h-full ml-5 -mt-5">
            <h1 className="text-3xl" style={{ fontFamily: "Poppins-Light" ,color: textcolor}}>
            {userData?.firstName !=="undefined" && userData?.firstName !=="null" &&
            userData?.firstName !==undefined && userData?.firstName !==null? userData?.firstName : ""}{" "}

            {userData?.lastName !=="undefined" && userData?.lastName !=="null" &&
            userData?.lastName !==undefined && userData?.lastName !==null? userData?.lastName : ""}{" "}
            </h1>
            <p
              className="text-center text-sm "
              style={{ fontFamily: "Poppins-Light",color: textcolor }}
            >
              {userData?.businessName}
            </p>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="flex justify-between px-10 py-5">
        <div
        className="  "
        onClick={() => {
          const cleanedPhoneNum = userData.phoneNum.replace(/\s+/g, ""); // Remove all spaces
          window.open(`https://wa.me/${cleanedPhoneNum}`);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22.92"
          height="28.715"
          viewBox="0 0 26.92 28.715"
        >
          <g id="whatsapp-whats-app-svgrepo-com" transform="translate(-2)">
            <path
              id="Path_105961"
              data-name="Path 105961"
              d="M15.46,0A13.442,13.442,0,0,0,2,13.46a13.012,13.012,0,0,0,2.871,8.256L2.987,27.459a1.013,1.013,0,0,0,.269.987.64.64,0,0,0,.538.269.54.54,0,0,0,.359-.09l6.192-2.782A12.454,12.454,0,0,0,15.46,26.92,13.46,13.46,0,0,0,15.46,0Z"
              fill="#25d366"
            />
            <path
              id="Path_105962"
              data-name="Path 105962"
              d="M22.024,17.528a3.3,3.3,0,0,1-2.5,1.872,2.4,2.4,0,0,1-.78.078,8.279,8.279,0,0,1-3.2-.858A12.848,12.848,0,0,1,10.326,14.1v-.078A6.61,6.61,0,0,1,9,10.587a4.22,4.22,0,0,1,1.17-2.964A2.178,2.178,0,0,1,11.73,7h.39c.546,0,.936.156,1.326.936l.312.624a9.2,9.2,0,0,0,.624,1.4,1.2,1.2,0,0,1,0,1.248,1.4,1.4,0,0,1-.39.546q-.117.234-.234.234l-.156.156a7.138,7.138,0,0,0,1.326,1.638,5.712,5.712,0,0,0,2.028,1.248h0c.156-.156.312-.468.546-.7l.078-.156a1.34,1.34,0,0,1,1.638-.468c.312.156,2.028.936,2.028.936l.156.078c.234.156.546.234.7.546A3.69,3.69,0,0,1,22.024,17.528Z"
              transform="translate(0.28 0.221)"
              fill="#fff"
            />
          </g>
        </svg>
      </div>

      <div
      className="border-r border-l border-gray-300 px-10"
      onClick={() => {
        window.open(`mailto:${userData.email}`);
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28.372"
        height="28.715"
        viewBox="0 0 36.372 28.715"
      >
        <path
          id="envelope-Filled_1_"
          d="M556.547,2787.3l-13.726-9.744a8.606,8.606,0,0,1,7.293-4.059h19.143a8.612,8.612,0,0,1,7.293,4.04l-13.687,9.725A5.739,5.739,0,0,1,556.547,2787.3Zm21.287-5.954-12.788,9.074a9.73,9.73,0,0,1-5.379,1.627,9.513,9.513,0,0,1-5.283-1.608l-12.845-9.094c-.019.249-.038.517-.038.766V2793.6a8.627,8.627,0,0,0,8.614,8.614h19.143a8.627,8.627,0,0,0,8.614-8.614v-11.486C577.872,2781.866,577.853,2781.6,577.834,2781.349Z"
          transform="translate(-541.5 -2773.5)"
          fill="#e95f52"
        />
      </svg>
    </div>

    <div
    onClick={() => {
      const cleanedPhoneNum = userData.phoneNum.replace(/\s+/g, ""); // Remove all spaces
      window.open(`tel:${cleanedPhoneNum}`);
      window.open(`tel:${userData.phoneNum}`);
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.715"
      height="28.715"
      viewBox="0 0 28.715 28.715"
    >
      <g id="phone-Filled" transform="translate(0 0)">
        <path
          id="phone-Filled_1_"
          d="M760.688,2869.966a6.458,6.458,0,0,1-4.9,2.249h-.177A22.738,22.738,0,0,1,733.5,2850.1a6.469,6.469,0,0,1,2.263-5.1,6.087,6.087,0,0,1,5.061-1.407,5.237,5.237,0,0,1,.716.18,2.251,2.251,0,0,1,1.511,1.8l1.013,6.431a2.291,2.291,0,0,1-.818,2.132c-.127.1-.258.2-.4.292a.814.814,0,0,0-.326,1.007,14.9,14.9,0,0,0,7.747,7.749.819.819,0,0,0,1.014-.335c.071-.1.145-.2.221-.3a2.287,2.287,0,0,1,2.163-.827l6.547,1.089a2.226,2.226,0,0,1,1.792,1.562c.041.146.08.313.113.5A6.158,6.158,0,0,1,760.688,2869.966Z"
          transform="translate(-733.499 -2843.501)"
          fill="#0288d1"
        />
      </g>
    </svg>
  </div>

        </div>
        <div className="flex justify-start items-center  gap-2 mt-4 ml-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.5"
            height="20.5"
            viewBox="0 0 22.5 28.5"
          >
            <path
              id="map-marker-Filled_1_"
              d="M221.75,2782.75a3,3,0,1,1-3-3A3.009,3.009,0,0,1,221.75,2782.75Zm8.25,0c0,9.12-7.005,14.835-10.005,16.86a2.11,2.11,0,0,1-1.245.39,2.188,2.188,0,0,1-1.26-.39c-3-2.025-9.99-7.725-9.99-16.86a11.25,11.25,0,0,1,22.5,0Zm-5.25,0a6,6,0,1,0-6,6A6,6,0,0,0,224.75,2782.75Z"
              transform="translate(-207.5 -2771.5)"
              fill="#E4405F"
            />
          </svg>
          <p
            className=" text-xs"
            style={{ fontFamily: "Poppins-Light" ,color: textcolor}}
          >
            {userData.location}
          </p>
        </div>
        {userData?.jobTitle !== null &&
        userData?.jobTitle !== "null" &&
        userData?.jobTitle !== "undefined" &&
        userData?.jobTitle !== undefined ? (
          <div className="w-[90%] rounded-2xl p-4 mt-1">
            <h1
              className="text-xl mb-2"
              style={{ fontFamily: "Poppins-Light",color: textcolor }}
            >
              Job Title
            </h1>
            <p
              className="text-xs overflow-auto "
              style={{ fontFamily: "Poppins-Light",color: textcolor }}
            >
              {userData?.jobTitle}
            </p>
          </div>
        ) : (
          ""
        )}

        {userData?.bio !== null && userData?.bio !== "null" && (
          <div className="w-[90%] rounded-2xl p-4">
            <h1 className="text-xl" style={{ fontFamily: "Poppins-Light",color: textcolor }}>
              About Me
            </h1>
            <p
              className="text-xs overflow-auto "
              style={{ fontFamily: "Poppins-Light",color: textcolor }}
            >
              {userData?.bio}
            </p>
          </div>
        )}
        <button className="fixed z-50 bottom-2 left-1 w-14 h-14 rounded-full flex justify-center items-center text-[var(--button-text-color)]">
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 260,
                        p: 4,
                    }}
                >
                    <div
                        style={{
                            borderRadius: "20px", // Adjust as needed for roundness
                            overflow: "hidden",
                        }}
                    >
                        <QRCode
                            value={urlToShare}
                            size={200}
                            fgColor={textcolor}
                            bgColor={userData?.bgColor}
                            level="M"
                            includeMargin={true}
                            renderAs="svg"
                        />
                    </div>
                </Box>
            </Modal>
            <Box sx={{ height: 1 , transform: 'translateZ(0px)', flexGrow: 1}}  style={{
              bottom: "0px",
              left: "0px",
              whiteSpace: "nowrap",
              zIndex: "0",
              color: `white`,
              
              position:"fixed",
              zIndex:100,
            }}>
    <SpeedDial
    onClick={() => {setOppen(!oppen);setAnimate(!animate)}}
      ariaLabel="SpeedDial basic example"
      
      sx={{ 
        position: 'absolute', 
        bottom: 10, 
        left: 16, 
        marginBottom:"30px",
        zIndex: 40, 
        '& .MuiSpeedDial-fab': {
          backgroundColor: `${userData?.buttonColor} !important`,
          '&:hover': {
            backgroundColor: `${userData?.buttonColor} !important`
                      }
        },
        marginLeft:"-20px"
      }}
     
      style={{color: textbottoncolor}}
      icon={
        <div
          style={{
            border: "2px solid",
            borderColor: textbottoncolor,
            borderRadius: "50%",
            padding: "12px",
          }}
        >
          <PiUploadSimpleBold size={28}       style={{color: textbottoncolor}} />
        </div>
      }
      ref={speedDialRef}
      open={oppen}
    >
          
       <SpeedDialAction
       sx={{
    borderTop: '1px solid grey',
    borderRadius: '8px',
    backgroundColor: userData?.buttonColor,
    padding: '8px',
    marginTop: '-2px', // Reduced margin to make the actions closer
    transition: 'all 0.3s ease-in-out',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: userData?.buttonColor,
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
    },
    width:"15.5vh",
     ...(animate ? { animation: 'slide-from-right 0.7s ease-in-out' } : {}),
  }}
          icon=
          <div className='flex' onClick={handleOpen}>
          <IoQrCodeOutline  style={{color: textbottoncolor}} size={15}  />
           <p
           style={{color: textbottoncolor}}
          className={`text-[8px] pl-2 ${animate ? 'animate-slide-from-right' : ''}`}>my qr code</p>
          </div>
          
        >
        </SpeedDialAction>
        <SpeedDialAction
         sx={{
    borderTop: '1px solid grey',
    borderRadius: '8px',
    backgroundColor: userData?.buttonColor,
    padding: '8px',
    marginTop: '-2px', // Reduced margin to make the actions closer
    transition: 'all 0.3s ease-in-out',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: userData?.buttonColor,
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
    },
    width:"15.5vh",
     ...(animate ? { animation: 'slide-from-right 0.4s ease-in-out' } : {}),
  }}
          icon=
          <div className='flex '    style={{color: textbottoncolor}} onClick={handleCopyLink}>
          <FaRegCopy size={14}  color={userData?.textbottoncolor}/>
          <p   style={{color: textbottoncolor}} className={`text-[9px] pl-2 ${animate ? 'animate-slide-from-right' : ''}`}>copy link</p>
          </div>
          > 
          </SpeedDialAction>
          
          <SpeedDialAction
          sx={{
    borderTop: '1px solid grey',
    borderRadius: '8px',
    backgroundColor: userData?.buttonColor,
    padding: '8px',
    marginTop: '-2px', // Reduced margin to make the actions closer
    transition: 'all 0.3s ease-in-out',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: userData?.buttonColor,
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
    },
    width:"15.5vh",
     ...(animate ? { animation: 'slide-from-right 0.3s ease-in-out' } : {}),
  }}
          icon=
          <div className='flex '  onClick={() => setOppen(!oppen)}>
        
        


          <RWebShare
          data={{
            url: urlToShare,
            title: "Share your X tag link",
          }}
          onClick={() => setOppen(!oppen)}
        >
          <div className="flex -ml-4 -mt-2">
          
            <IoShareSocial size={15} className="w-4 h-4 mt-[10px] ml-2 z-50"color={textbottoncolor}/>

            <p style={{color: textbottoncolor}} className={`text-[9px] pl-2 mt-[10px] ${animate ? 'animate-slide-from-right' : ''}`}>SHARE LINK</p>
          </div>
        </RWebShare>
          </div>
          > 
          </SpeedDialAction>
    
     
    </SpeedDial>
  </Box>


        </button>
        {isVisible && (
          <div className="z-50 fixed bottom-16 left-1/2 transform -translate-x-1/2 border-2 bg-gray-700 text-white rounded-xl px-2">
              link copied
          </div>
      )}

<div onClick={saveToContacts}


        style={{ backgroundColor: userData?.buttonColor ,color: textbottoncolor,border:"2px solid",borderColor:textbottoncolor}}
       className='flex items-center  fixed right-2 rounded-full mb-2 bottom-[35px]  text-center text-xs px-1 z-50'>
       <div 
       style={{ backgroundColor: userData?.buttonColor,borderColor:textbottoncolor}}
           className='w-fit py-3 px-3 rounded-full -ml-2 border-2  p-1'><FaPlus color={userData?.textbottoncolor} size={19} className=''/></div>
             <p
             
             className=" whitespace-nowrap  pl-[2px] text-[14px] font-bold " style={{color: textbottoncolor,fontWeight:'bold'}}>Add To Contacts</p>   
             </div>


        <div className="w-[90%] rounded-2xl p-4 mt-10">
          <h1 className="text-xl " style={{ fontFamily: "Poppins-Light",color: textcolor }}>
            Social Media
          </h1>
          <div className="flex justify-center items-center h-full">
          <div className="grid grid-cols-3 gap-3 mt-3 pl-3 overflow-hidden">
                     
          {userData?.primary_links?.map((primaryItem, primaryIndex) => {
            return (
              <div
                className="w-[50px] rounded-md flex justify-center items-center"
                key={primaryIndex}
              >
                {primaryItem.name !== "Telegram" ? (
                  <a
                    href={
                      primaryItem.value && primaryItem.value.startsWith("http")
                        ? primaryItem.value
                        : `https://${primaryItem.value}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      handlePrimaryLinkClick(primaryItem);
                    }}
                  >
                    <img
                      src={primaryItem.logo}
                      alt=""
                      className="w-[100%] h-[100%]"
                    />
                  </a>
                ) : (
                  <a
                    href={`https://t.me/${primaryItem.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      handlePrimaryLinkClick(primaryItem);
                    }}
                  >
                    <img src={primaryItem.logo} alt="" />
                  </a>
                )}
              </div>
            );
          })}
          {userData?.second_links?.map((primaryItem, primaryIndex) => {
            return (
              <div
                className="w-14 rounded-md bg-gray-200 flex justify-center items-center"
                key={primaryIndex}
              >
                <a
                  href={primaryItem?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    handleLinkClick(primaryItem);
                  }}
                >
                  <img
                    src={primaryItem.logo}
                    alt=""
                    className="w-[100%] h-[100%]"
                  />
                </a>
              </div>
            );
          })}
       
              </div>
              </div>
        
        </div>
   

 <div className="flex justify-center items-center mt-10 " style={{ backgroundColor: userData?.bgColor }}>
 <div className="w-[70%] flex flex-col gap-3">
   {userData?.section.map((item, index) => {
     const url = item.media;
     const isPDF = url.endsWith(".pdf");

     return (
       <>
         {!isPDF && (
           <div className="flex justify-center">
             <h2
               className="text-xl md:text-xl font-bold"
               style={{color: textcolor }}
             >
               {item.title}
             </h2> 
          
           </div>
         )}

         <div
           key={index}
           className="flex flex-col gap-2 justify-center items-center"
           style={{ backgroundColor: userData?.bgColor }}
         >
           {isPDF ? (
            <div className="flex flex-col items-center justify-center mb-14">
            <h2
              className="text-xl md:text-xl p-4 font-bold"
              style={{ color: textcolor }}
            >
              {item.title}
            </h2>
            <div className='w-[250px]  rounded-[10px] border-4' style={{backgroundColor:userData?.buttonColor,borderColor:textcolor,borderRadius: "20px"}}>
            <a href={url} target='_blank'  >
                <div className='flex justify-center  w-full    shadow-lg  relative'  >
                <div className='flex flex-row gap-5'>
              <BsFiletypePdf
                              size={90}
                              className="w-full mt-5 mb-5 "
                              color={textbottoncolor}
                            />

                </div>
                <div className='absolute top-3 right-3'>
                <svg id="eye-Filled" xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                <rect id="Path" width="26" height="24" fill="rgba(255,255,255,0)"/>
                <path fill={textbottoncolor} id="eye-Filled_1_" d="M369.234,2491.241c-1.375-2.14-4.444-5.74-9.612-5.74s-8.237,3.6-9.6,5.74a3.224,3.224,0,0,0,0,3.51c1.364,2.15,4.444,5.75,9.6,5.75s8.237-3.6,9.612-5.75a3.27,3.27,0,0,0,.522-1.75A3.33,3.33,0,0,0,369.234,2491.241Zm-9.612,6.26a4.509,4.509,0,1,1,4.8-4.5A4.662,4.662,0,0,1,359.622,2497.5Zm2.653-4.64a.619.619,0,0,1,.011.14,2.669,2.669,0,1,1-2.664-2.5,2.759,2.759,0,0,1,1.705.58,1.016,1.016,0,0,0-.639.92,1.037,1.037,0,0,0,1.066,1A1.014,1.014,0,0,0,362.276,2492.861Z" transform="translate(-346.836 -2481)"/>
                </svg>
                </div>
                </div>
            </a>
        </div>

          </div>


           ) : (
             <div className=" flex justify-center" style={{ backgroundColor: userData?.bgColor }}>
             




             <video
             className={`rounded-3xl border-4 min-w-[250px] h-[150px] aspect-video md:aspect-video mb-14 w-64 md:w-[43%]`}
             style={{
               borderColor: `${textcolor}`,
               backgroundColor: `${userData?.buttonColor}`,
             }}
             controls
             autoPlay 
             muted  // This will mute the video, enabling it to autoplay in more browsers
             preload="auto"  // This suggests to the browser that it should load the entire video when the page loads
         
             autoplay
           >
             <source src={url} type="video/mp4" />
           </video>

             </div>
           )}
         </div>
       </>
     );
   })}
 </div>
</div>

<div classname="" >
          
 <a href="https://www.x-tag.tech/" target='_blank'
 className="absolute   w-screen left-0 -mt-10 mb-10  flex justify-center  "
 style={{ color: textcolor }}
>
{
  textcolor=='#ffffff'?

    <img src={powerdByXTagWhite} className='w-[200px] '/>
    :
    <img src={powerdByXTagBlack} className='w-[200px] '/>
  }

</a>





        </div>







      </div>
    </div>
  );
};

export default Template13;
