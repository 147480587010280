import { FaPhone } from "react-icons/fa6";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { MdMarkEmailRead } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { FaPlus } from "react-icons/fa";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { FaPhoneVolume } from "react-icons/fa";
import "../App.css";
import { Box, SpeedDial } from "@mui/material";
import { IoLogoWhatsapp } from "react-icons/io";
import React, { useEffect, useState, useRef } from "react";
import { PiUploadSimpleBold } from "react-icons/pi";
import { RWebShare } from "react-web-share";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import tinycolor from "tinycolor2";
import QRCode from "qrcode.react";
import Modal from "@mui/material/Modal";
import copy from "../assets/pngwing.com (11).png";
import share from "../assets/pngwing.com (9).png";
import { MdEmail } from "react-icons/md";
import qr from "../assets/pngwing.com (10).png";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BsFiletypePdf } from "react-icons/bs";
import CircularProgress from "@mui/material/CircularProgress"; 
import { pdfjs } from "react-pdf";
const Templatetwo = () => {
  const cards = [
    { icon: <FaPhone />, name: "Phone Number" },
    { icon: <AiOutlineMail />, name: "Email" },
    { icon: <FaFacebook />, name: "Facebook" },
    { icon: <FaInstagram />, name: "instagram" },
    { icon: <FaSquareXTwitter />, name: "twitter" },
    { icon: <FaLinkedinIn />, name: "linkedin" },
  ];

  const [oppen, setOppen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userData, setUserData] = useState(null);
  const [urlToShare, seturlToShare] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state variable for loading status
  const [opennnn, setOpennnn] = useState(false);
  const handleCloseeee = () => setOpennnn(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [animate, setAnimate] = useState(false);
  const handleImageClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  let retryCount = 0;
  const maxRetryCount = 3; // Maximum number of retries
  const retryDelay = 2000; // Delay between retries in milliseconds
  const getCurrentUrl = () => {
    seturlToShare(window.location.href);
  };
  const retryFetch = () => {
    if (retryCount < maxRetryCount) {
      setTimeout(() => {
        fetchData();
        retryCount++;
      }, retryDelay);
    } else {
      console.error("Maximum retry attempts reached.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrentUrl();
  }, []);
  const opene = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const speedDialRef = useRef();
  const location = useLocation();

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const userParam = query.get("user");

  console.log("userParam", userParam);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      try {
        const response = await axios.get(
          `https://api.x-tag.tech/api/user/${userParam}`
        );

        setUserData(response.data.data);
        console.log('response.data.data',response.data.data);
        document.documentElement.style.setProperty(
          "--bg-color",
          response.data.data.bgColor
        );
        document.documentElement.style.setProperty(
          "--button-bg-color",
          response.data.data.buttonColor
        );
        document.documentElement.style.setProperty(
          "--border-color",
          response.data.data.buttonColor
        );

        if (apicounter == 0) {
          axios.post(
            `https://api.x-tag.tech/api/profile/${response.data.data?.id}/visit`,
            {}
          );
          apicounter++;
          console.log(apicounter);
        }
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.error("Server responded with error:", error.response.status);

          setIsLoading(false);
        } else if (error.request) {
          console.error("No response received:", error.request);
          retryFetch();
        } else {
          console.error("Error setting up request:", error.message);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [userParam]);
  let apicounter = 0;

  const [textcolor, setTextColor] = useState("#ffffff");
  const [textbottoncolor, setTextBottonColor] = useState("#ffffff");

  useEffect(() => {
    const mainBackgroundColor = userData?.bgColor;

    if (mainBackgroundColor) {
      const brightness = tinycolor(mainBackgroundColor).getBrightness();

      setTextColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [userData?.bgColor]);

  useEffect(() => {
    const buttonsBackground = userData?.buttonColor;

    if (buttonsBackground) {
      const brightness = tinycolor(buttonsBackground).getBrightness();

      // Adjust text color based on brightness
      setTextBottonColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [userData?.buttonColor]);
  const saveAsPDF = async () => {
    const targetComponent = document.getElementById("mainComponent");
    if (targetComponent) {
      try {
        const canvas = await html2canvas(targetComponent);
        const imgData = canvas.toDataURL("image/png");

        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([canvas.width, canvas.height]);
        const { width, height } = page.getSize();
        const img = await pdfDoc.embedPng(imgData);
        const { width: imgWidth, height: imgHeight } = img.scale(1);

        page.drawImage(img, {
          x: 0,
          y: 0,
          width: imgWidth,
          height: imgHeight,
        });

        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], { type: "application/pdf" });

        // Use FileSaver.js library to save the blob as a file
        const saveAs = require("file-saver");
        saveAs(blob, "screenshot.pdf");
      } catch (error) {
        console.error("Error saving as PDF:", error);
      }
    }
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const params = new URLSearchParams(window.location.search);

  const profileValue = params.get("profile");

  console.log("Profile:", profileValue);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCopyLink = () => {
    const linkToCopy = urlToShare;

    // Create a temporary input element
    const tempInput = document.createElement("input");
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);

    tempInput.select();
    document.execCommand("copy");

    // Remove the temporary input
    document.body.removeChild(tempInput);
    // toast.success('Link copied!');

    console.log("Link copied to clipboard:", linkToCopy);
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 1000); // 5000 milliseconds (5 seconds)

    // You can add additional logic or feedback here if needed
  };

  const handleDocumentClick = (e) => {
    if (speedDialRef.current && !speedDialRef.current.contains(e.target)) {
      // Click occurred outside the SpeedDial, hide it
      setOppen(false);
    }
  };
  const toDataURL = (url) =>
    axios
      .get(url, {
        responseType: "blob", // Important to handle the response as a Blob
        headers: {},
      })
      .then((response) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(response.data);
        });
      })
      .catch((error) => {
        console.error("Error in toDataURL with axios:", error);
        return null;
      });

  const saveToContacts = async () => {
    const contact = {
      name: userData?.firstName + " " + userData?.lastName,
      mobile: userData?.phoneNum || "",
      email: userData?.email || "",
      address: userData?.location || "",
      organization: userData?.businessName || "",
      xTagWebsite: urlToShare,
      photo: userData?.photo || "",
      primary_links: userData?.primary_links || [],
    };

    let imageDataUrl = "";
    if (contact.photo !== "") {
      imageDataUrl = await toDataURL(contact.photo);
      console.log("imageDataUrl,imageDataUr", imageDataUrl);
      if (!imageDataUrl) {
        console.error("Failed to convert image to Data URL");
        return;
      }
    }

    let vcard =
      `BEGIN:VCARD\nVERSION:3.0\n` +
      `N:${contact.name};;;\n` +
      `FN:${contact.name}\n` +
      `TEL;CELL:${contact.mobile}\n` +
      `EMAIL;HOME:${contact.email}\n` +
      `ADR;HOME:${contact.address}\n` +
      `ORG;WORK:${contact.organization}\n` +
      `item1.URL:${contact.xTagWebsite}\n` +
      `item1.X-ABLabel:X TAG WEBSITE\n` +
      `PHOTO;ENCODING=b;TYPE=JPEG:${imageDataUrl.split(",")[1]}\n`;

    contact?.primary_links?.forEach((link, index) => {
      const itemNumber = index + 2; // Starting from 2 as 1 is used for xTagWebsite
      vcard +=
        `item${itemNumber}.URL:${link.value}\n` +
        `item${itemNumber}.X-ABLabel:${link.name}\n`;
    });

    vcard += `END:VCARD`;

    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    // Create a hidden download link and trigger it
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${contact.name}.vcf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleLinkClick = async (primaryItem) => {
    const linkId = primaryItem.id; // Assuming each item has an ID

    try {
      await axios.post(
        `https://api.x-tag.tech/api/link/${linkId}/visit`
      );
      console.log("API called for link:", linkId);
    } catch (error) {
      console.error("Error calling API for link:", linkId, error);
    }
  };
  const handlePrimaryLinkClick = async (primaryItem) => {
    const linkId = primaryItem.id; // Assuming each item has an ID
console.log('primaryItem',primaryItem)
    try {
      await axios.post(
        `https://api.x-tag.tech/api/${userData.id}/primary_link/${linkId}/visit`
      );
      console.log("API called for link:", linkId);
    } catch (error) {
      console.error("Error calling API for link:", linkId, error);
    }
  };
  function hexToRgba(hex, opacity = 1) {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (hex?.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex?.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r},${g},${b},${opacity})`;
  }
  const startColor = hexToRgba(userData?.bgColor, 0); // Fully transparent
  const endColor = hexToRgba(userData?.bgColor, 1); // Fully opaque

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress /> {/* Loading spinner */}
      </div>
    );
  }
  return (
    <>
      <div className="w-full relative" style={{ backgroundColor: userData?.bgColor }}>
        <div className="fixed w-screen h-screen -z-10" style={{ backgroundColor: userData?.bgColor }}></div>
        <button
          onClick={saveToContacts}
          className="fixed z-50 bottom-5 w-36 h-14 bg-[var(--button-bg-color)] rounded-3xl border-2  text-[var(--button-text-color)] font-bold text-sm right-2"
          style={{ border: "2px solid", borderColor: { textbottoncolor } }}
        >
          <div
            className="flex flex-row gap-2 justify-center items-center text-center w-full"
            style={{ color: textbottoncolor }}
          >
            <div className="flex flex-col">
              <span className="text-sm">Add To</span>
              <span className="text-sm">Contacts</span>
            </div>
            <div className="border p-2 rounded-full">
              <FaPlus />
            </div>
          </div>
        </button>
        <div>
          <div
            className="relative mb-10"
            style={{
              backgroundImage: `url(${userData?.cover})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "300px",
              zIndex: 10,
              transition: "0.5s filter linear",
            }}
          >
            {/* Black overlay with 50% opacity */}
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
                opacity: 0.5,
                zIndex: 15, // Ensure it is above the background but below the content
              }}
            ></div>
  
            <div style={{
              width: '100vw',
              height: '501px',
              marginTop: '-81px',
              zIndex: 50,
              position: 'absolute',
              bottom: 0,
              background: `linear-gradient(to bottom, ${startColor}, ${endColor})`
            }}></div>
  
            {/* Content overlay */}
            <div className="absolute top-0 h-[300px] w-full z-30 ssss" style={{ zIndex: 80 }}>
              <div className="absolute bottom-5 left-5 text-white text-3xl font-bold z-50" style={{ zIndex: 80 }}>
                <h2 className="pt-12 md:pt-16 text-4xl font-bold Robote_bold" style={{ color: textcolor }}>
                  {userData?.firstName !== "undefined" && userData?.firstName !== "null" &&
                    userData?.firstName !== undefined && userData?.firstName !== null ? userData?.firstName : ""}{" "}
                  {userData?.lastName !== "undefined" && userData?.lastName !== "null" &&
                    userData?.lastName !== undefined && userData?.lastName !== null ? userData?.lastName : ""}{" "}
                </h2>
                {userData?.businessName && userData?.businessName !== "null" && (
                  <h2 className="text-3xl font-bold" style={{ color: textcolor }}>
                    {userData?.businessName}
                  </h2>
                )}
                {userData?.jobTitle && userData?.jobTitle !== "null" && (
                  <h2 className="text-sm Robote my-1" style={{ color: textcolor }}>
                    {userData?.jobTitle}
                  </h2>
                )}
                {userData?.location && userData?.location !== "null" && (
                  <h2 className="text-sm Robote" style={{ color: textcolor }}>
                    {userData?.location}
                  </h2>
                )}
              </div>
            </div>
  
            {/* Bottom gradient for merging effect */}
            <div className="absolute w-full h-4 -bottom-2 left-0 right-0" style={{ background: `linear-gradient(to bottom, transparent, ${userData?.bgColor})` }}></div>
          </div>
  
          <div className="flex justify-around mt-2">
            <button
              className="text-[40px] md:text-[50px]"
              onClick={() => {
                const cleanedPhoneNum = userData.phoneNum.replace(/\s+/g, ""); // Remove all spaces
                window.open(`https://wa.me/${cleanedPhoneNum}`);
              }}
            >
              <IoLogoWhatsapp color={textcolor} />
            </button>
            <button
              className="text-[40px] md:text-[50px]"
              onClick={() => {
                window.open(`mailto:${userData.email}`);
              }}
            >
              <MdEmail color={textcolor} />
            </button>
            <button
              className="text-[40px] md:text-[50px] r rotate-140"
              onClick={() => {
                const cleanedPhoneNum = userData.phoneNum.replace(/\s+/g, ""); // Remove all spaces
                window.open(`tel:${cleanedPhoneNum}`);
                window.open(`tel:${userData.phoneNum}`);
              }}
            >
              <FaPhoneVolume color={textcolor} size={35} className='-rotate-[30deg]' />
            </button>
          </div>
       
            <div className="w-screen flex justify-center">
              <div className="py-5 w-[90%]">
              <div className="grid grid-cols-3 justify-center items-center gap-y-7 mt-20 place-items-center">
              {userData?.primary_links?.map((primaryItem, primaryIndex) => {
                return (
                  <div key={primaryIndex}>
                    <button className="flex justify-between w-[100%]">
                      <div className="flex flex-row">
                        <div className="w-[50px] h-[50px] flex items-center">
                          <div className="rounded-full text-2xl">
                            {primaryItem.name !== "Telegram" ? (
                              <a
                                href={
                                  primaryItem.value && primaryItem.value.startsWith("http")
                                    ? primaryItem.value
                                    : `https://${primaryItem.value}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                  handlePrimaryLinkClick(primaryItem);
                                }}
                              >
                                <img src={primaryItem.logo} alt="" className="w-full h-full" />
                              </a>
                            ) : (
                              <a
                                href={`https://t.me/${primaryItem.value}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                  handlePrimaryLinkClick(primaryItem);
                                }}
                              >
                                <img src={primaryItem.logo} alt="" className="" />
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                );
              })}
              {userData?.second_links?.map((primaryItem, primaryIndex) => {
                return (
                  <div key={primaryIndex}>
                    <button className="flex justify-between w-[100%]">
                      <div className="flex flex-row">
                        <div className="w-[50px] h-[50px] flex items-center">
                          <div className="rounded-full text-2xl">
                            <a
                              href={primaryItem?.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => {
                                handleLinkClick(primaryItem);
                              }}
                            >
                              <img src={primaryItem.logo} alt="" className="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                );
              })}
            </div>
              </div>
            </div>
        
        </div>
        <div className="flex justify-center items-center mt-14">
          <div className="w-[70%] flex flex-col gap-3">
            {userData?.section.map((item, index) => {
              const url = item.media;
              const isPDF = url.endsWith(".pdf");
  
              return (
                <>
                  {!isPDF && (
                    <div className="flex justify-center">
                      <h2
                        className="text-2xl md:text-3xl"
                        style={{ color: textcolor }}
                      >
                        {item.title}
                      </h2>
                    </div>
                  )}
  
                  <div
                    key={index}
                    className="flex flex-col gap-2 justify-center items-center"
                  >
                    {isPDF ? (
                      <div className="flex flex-col items-center justify-center mb-14">
                      <h2
                        className="text-2xl md:text-3xl p-4"
                        style={{ color: textcolor }}
                      >
                        {item.title}
                      </h2>
                      <div className='w-[250px]  rounded-[10px] border-4' style={{backgroundColor:userData?.buttonColor,borderColor:textcolor,borderRadius: "20px"}}>
                      <a href={url} target='_blank'  >
                          <div className='flex justify-center  w-full    shadow-lg  relative'  >
                          <div className='flex flex-row gap-5'>
                        <BsFiletypePdf
                                        size={90}
                                        className="w-full mt-5 mb-5 "
                                        color={textbottoncolor}
                                      />
          
                          </div>
                          <div className='absolute top-3 right-3'>
                          <svg id="eye-Filled" xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                          <rect id="Path" width="26" height="24" fill="rgba(255,255,255,0)"/>
                          <path fill={textbottoncolor} id="eye-Filled_1_" d="M369.234,2491.241c-1.375-2.14-4.444-5.74-9.612-5.74s-8.237,3.6-9.6,5.74a3.224,3.224,0,0,0,0,3.51c1.364,2.15,4.444,5.75,9.6,5.75s8.237-3.6,9.612-5.75a3.27,3.27,0,0,0,.522-1.75A3.33,3.33,0,0,0,369.234,2491.241Zm-9.612,6.26a4.509,4.509,0,1,1,4.8-4.5A4.662,4.662,0,0,1,359.622,2497.5Zm2.653-4.64a.619.619,0,0,1,.011.14,2.669,2.669,0,1,1-2.664-2.5,2.759,2.759,0,0,1,1.705.58,1.016,1.016,0,0,0-.639.92,1.037,1.037,0,0,0,1.066,1A1.014,1.014,0,0,0,362.276,2492.861Z" transform="translate(-346.836 -2481)"/>
                          </svg>
                          </div>
                          </div>
                      </a>
                  </div>
  
                    </div>


                    ) : (
                      <div className="flex justify-center">
                        <video
                          className={`rounded-3xl border-4 min-w-[250px] h-[150px] aspect-video md:aspect-video mb-14 w-64 md:w-[43%]`}
                          style={{
                            borderColor: `${textcolor}`,
                            backgroundColor: `${userData?.buttonColor}`,
                          }}
                          controls
                        >
                          <source src={url} type="video/mp4" className="" />
                        </video>
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </div>
  
        <button className="fixed z-50 bottom-2 left-1 w-14 h-14 rounded-full flex justify-center items-center text-[var(--button-text-color)]">
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 260,
                        p: 4,
                    }}
                >
                    <div
                        style={{
                            borderRadius: "20px", // Adjust as needed for roundness
                            overflow: "hidden",
                        }}
                    >
                        <QRCode
                            value={urlToShare}
                            size={200}
                            fgColor={textcolor}
                            bgColor={userData?.bgColor}
                            level="M"
                            includeMargin={true}
                            renderAs="svg"
                        />
                    </div>
                </Box>
            </Modal>
            <Box sx={{ height: 1 , transform: 'translateZ(0px)', flexGrow: 1}}  style={{
              bottom: "0px",
              left: "0px",
              whiteSpace: "nowrap",
              zIndex: "0",
              color: `white`,
              
              position:"fixed",
              zIndex:100,
            }}>
    <SpeedDial
    onClick={() => {setOppen(!oppen);setAnimate(!animate)}}
      ariaLabel="SpeedDial basic example"
      
      sx={{ 
        position: 'absolute', 
        bottom: 10, 
        left: 16, 
        zIndex: 40, 
        '& .MuiSpeedDial-fab': {
          backgroundColor: `${userData?.buttonColor} !important`,
          '&:hover': {
            backgroundColor: `${userData?.buttonColor} !important`
                      }
        },
        marginLeft:"-20px"
      }}
     
      style={{color: textbottoncolor}}
      icon={
        <div
          style={{
            border: "2px solid",
            borderColor: textbottoncolor,
            borderRadius: "50%",
            padding: "12px",
          }}
        >
          <PiUploadSimpleBold size={28}       style={{color: textbottoncolor}} />
        </div>
      }
      ref={speedDialRef}
      open={oppen}
    >
          
       <SpeedDialAction
       sx={{
    borderTop: '1px solid grey',
    borderRadius: '8px',
    backgroundColor: userData?.buttonColor,
    padding: '8px',
    marginTop: '-2px', // Reduced margin to make the actions closer
    transition: 'all 0.3s ease-in-out',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: userData?.buttonColor,
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
    },
    width:"15.5vh",
     ...(animate ? { animation: 'slide-from-right 0.7s ease-in-out' } : {}),
  }}
          icon=
          <div className='flex' onClick={handleOpen}>
          <IoQrCodeOutline  style={{color: textbottoncolor}} size={15}  />
           <p
           style={{color: textbottoncolor}}
          className={`text-[8px] pl-2 ${animate ? 'animate-slide-from-right' : ''}`}>my qr code</p>
          </div>
          
        >
        </SpeedDialAction>
        <SpeedDialAction
         sx={{
    borderTop: '1px solid grey',
    borderRadius: '8px',
    backgroundColor: userData?.buttonColor,
    padding: '8px',
    marginTop: '-2px', // Reduced margin to make the actions closer
    transition: 'all 0.3s ease-in-out',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: userData?.buttonColor,
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
    },
    width:"15.5vh",
     ...(animate ? { animation: 'slide-from-right 0.4s ease-in-out' } : {}),
  }}
          icon=
          <div className='flex '    style={{color: textbottoncolor}} onClick={handleCopyLink}>
          <FaRegCopy size={14}  color={userData?.textbottoncolor}/>
          <p   style={{color: textbottoncolor}} className={`text-[9px] pl-2 ${animate ? 'animate-slide-from-right' : ''}`}>copy link</p>
          </div>
          > 
          </SpeedDialAction>
          
          <SpeedDialAction
          sx={{
    borderTop: '1px solid grey',
    borderRadius: '8px',
    backgroundColor: userData?.buttonColor,
    padding: '8px',
    marginTop: '-2px', // Reduced margin to make the actions closer
    transition: 'all 0.3s ease-in-out',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: userData?.buttonColor,
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
    },
    width:"15.5vh",
     ...(animate ? { animation: 'slide-from-right 0.3s ease-in-out' } : {}),
  }}
          icon=
          <div className='flex '  onClick={() => setOppen(!oppen)}>
        
        


          <RWebShare
          data={{
            url: urlToShare,
            title: "Share your X tag link",
          }}
          onClick={() => setOppen(!oppen)}
        >
          <div className="flex -ml-4 -mt-2">
          
            <IoShareSocial size={15} className="w-4 h-4 mt-[10px] ml-2 z-50"color={textbottoncolor}/>

            <p style={{color: textbottoncolor}} className={`text-[9px] pl-2 mt-[10px] ${animate ? 'animate-slide-from-right' : ''}`}>SHARE LINK</p>
          </div>
        </RWebShare>
          </div>
          > 
          </SpeedDialAction>
    
     
    </SpeedDial>
  </Box>


        </button>
        {isVisible && (
          <div className="z-50 fixed bottom-16 left-1/2 transform -translate-x-1/2 border-2 bg-gray-700 text-white rounded-xl px-2">
              link copied
          </div>
      )}

      </div>
      <div>
        <h2
          className="absolute cursor-pointer text-xs 2sm:text-md font-semibold Robote text-center w-screen left-0 z-50 -bottom-[68%] 2sm:-bottom-[75%]"
          style={{ color: textcolor }}
        >
          <Modal
            open={opennnn}
            onClose={handleCloseeee}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box>
              <h1 className="text-center -mt-5 mb-5">Contact Us Via:</h1>
              <div className="flex flex-col items-center ">
                <div
                  className="flex cursor-pointer justify-around items-center pt-1 w-[50%]"
                  onClick={() => {
                    window.open(`mailto:info.x-tag.tech`);
                  }}
                >
                  <MdMarkEmailRead
                    size={26}
                    className="border-2 border-black p-1"
                  />
                  <h1>EMAIL</h1>
                </div>
              </div>
            </Box>
          </Modal>
        </h2>
      </div>
    </>
  );
  
};

export default Templatetwo;
