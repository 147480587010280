import React , {useEffect , useState , useRef} from 'react'
import image from "../assets/background2.jpeg";
import { FaFacebookF } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { MdMarkEmailRead } from "react-icons/md";
import QRCode from "qrcode.react";
import { RWebShare } from "react-web-share";
import { useLocation } from "react-router-dom";
import { FaSnapchatGhost } from "react-icons/fa";
import { pdfjs } from "react-pdf";
import copy from "../assets/pngwing.com (11).png";
import share from "../assets/pngwing.com (9).png"; 
import qr from "../assets/pngwing.com (10).png";
import { FaPlus } from "react-icons/fa";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import tinycolor from "tinycolor2";
import { IoShareSocial } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa";
import { IoQrCodeOutline } from "react-icons/io5";
import { PiUploadSimpleBold } from "react-icons/pi";
import { Box, SpeedDial } from '@mui/material';
import Modal from "@mui/material/Modal";
import '../App.css'
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress"; 
import { BsFiletypePdf } from 'react-icons/bs';
import Pdfi from '../assets/PDF-icon.png'

const  TemplateFour = () => {

    const icons = [
    {icon:<FaFacebookF/> , name:"Facebook"},
    {icon:<FaLinkedinIn/> , name:"Linkdin"},
    {icon:<RiInstagramFill/> , name:"Instagram"},
    {icon:<FaTelegramPlane/> , name:"Telegram"},
    
  ]
  const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState(null);
  const [urlToShare, seturlToShare] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New state variable for loading status
  const [opennnn, setOpennnn] = useState(false);
  const handleCloseeee = () => setOpennnn(false);
  const [isModalOpen, setModalOpen] = useState(false);
  console.log(userData?.second_links,"asdaa")
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 270,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const handleImageClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  let retryCount = 0;
  const maxRetryCount = 3; // Maximum number of retries
  const retryDelay = 2000; // Delay between retries in milliseconds
  const getCurrentUrl = () => {
    seturlToShare(window.location.href);
  };
  const retryFetch = () => {
    if (retryCount < maxRetryCount) {
      setTimeout(() => {
        fetchData();
        retryCount++;
      }, retryDelay);
    } else {
      console.error("Maximum retry attempts reached.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrentUrl();
  }, []);
  const opene = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const speedDialRef = useRef();
  const location = useLocation();

  const [oppen, setOppen] = React.useState(false);
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const userParam = query.get("user");

  console.log("userParam", userParam);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      try {
        const response = await axios.get(
          `https://api.x-tag.tech/api/user/${userParam}`
        );

        setUserData(response.data.data);
        console.log(response.data.data);
        document.documentElement.style.setProperty(
          "--bg-color",
          response.data.data.bgColor
        );
        document.documentElement.style.setProperty(
          "--button-bg-color",
          response.data.data.buttonColor
        );
        document.documentElement.style.setProperty(
          "--border-color",
          response.data.data.buttonColor
        );

        if (apicounter == 0) {
          axios.post(
            `https://api.x-tag.tech/api/profile/${response.data.data?.id}/visit`,
            {}
          );
          apicounter++;
          console.log(apicounter);
        }
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.error("Server responded with error:", error.response.status);

          setIsLoading(false);
        } else if (error.request) {
          console.error("No response received:", error.request);
          retryFetch();
        } else {
          console.error("Error setting up request:", error.message);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [userParam]);
  let apicounter = 0;

  const [textcolor, setTextColor] = useState("#ffffff");
  const [textbottoncolor, setTextBottonColor] = useState("#ffffff");

  useEffect(() => {
    const mainBackgroundColor = userData?.bgColor;

    if (mainBackgroundColor) {
      const brightness = tinycolor(mainBackgroundColor).getBrightness();

      setTextColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [userData?.bgColor]);

  useEffect(() => {
    const buttonsBackground = userData?.buttonColor;

    if (buttonsBackground) {
      const brightness = tinycolor(buttonsBackground).getBrightness();

      // Adjust text color based on brightness
      setTextBottonColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [userData?.buttonColor]);
  const saveAsPDF = async () => {
    const targetComponent = document.getElementById("mainComponent");
    if (targetComponent) {
      try {
        const canvas = await html2canvas(targetComponent);
        const imgData = canvas.toDataURL("image/png");

        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([canvas.width, canvas.height]);
        const { width, height } = page.getSize();
        const img = await pdfDoc.embedPng(imgData);
        const { width: imgWidth, height: imgHeight } = img.scale(1);

        page.drawImage(img, {
          x: 0,
          y: 0,
          width: imgWidth,
          height: imgHeight,
        });

        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], { type: "application/pdf" });

        // Use FileSaver.js library to save the blob as a file
        const saveAs = require("file-saver");
        saveAs(blob, "screenshot.pdf");
      } catch (error) {
        console.error("Error saving as PDF:", error);
      }
    }
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const params = new URLSearchParams(window.location.search);

  const profileValue = params.get("profile");

  console.log("Profile:", profileValue);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCopyLink = () => {
    const linkToCopy = urlToShare;

    // Create a temporary input element
    const tempInput = document.createElement("input");
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);

    tempInput.select();
    document.execCommand("copy");

    // Remove the temporary input
    document.body.removeChild(tempInput);
    // toast.success('Link copied!');

    console.log("Link copied to clipboard:", linkToCopy);
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 1000); // 5000 milliseconds (5 seconds)

    // You can add additional logic or feedback here if needed
  };

  const handleDocumentClick = (e) => {
    if (speedDialRef.current && !speedDialRef.current.contains(e.target)) {
      // Click occurred outside the SpeedDial, hide it
      setOppen(false);
    }
  };
  const toDataURL = (url) =>
    axios
      .get(url, {
        responseType: "blob", // Important to handle the response as a Blob
        headers: {},
      })
      .then((response) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(response.data);
        });
      })
      .catch((error) => {
        console.error("Error in toDataURL with axios:", error);
        return null;
      });

  const saveToContacts = async () => {
    const contact = {
      name: userData?.firstName + " " + userData?.lastName,
      mobile: userData?.phoneNum || "",
      email: userData?.email || "",
      address: userData?.location || "",
      organization: userData?.businessName || "",
      xTagWebsite: urlToShare,
      photo: userData?.photo || "",
      primary_links: userData?.primary_links || [],
    };

    let imageDataUrl = "";
    if (contact.photo !== "") {
      imageDataUrl = await toDataURL(contact.photo);
      console.log("imageDataUrl,imageDataUr", imageDataUrl);
      if (!imageDataUrl) {
        console.error("Failed to convert image to Data URL");
        return;
      }
    }

    let vcard =
      `BEGIN:VCARD\nVERSION:3.0\n` +
      `N:${contact.name};;;\n` +
      `FN:${contact.name}\n` +
      `TEL;CELL:${contact.mobile}\n` +
      `EMAIL;HOME:${contact.email}\n` +
      `ADR;HOME:${contact.address}\n` +
      `ORG;WORK:${contact.organization}\n` +
      `item1.URL:${contact.xTagWebsite}\n` +
      `item1.X-ABLabel:X TAG WEBSITE\n` +
      `PHOTO;ENCODING=b;TYPE=JPEG:${imageDataUrl.split(",")[1]}\n`;

    contact?.primary_links?.forEach((link, index) => {
      const itemNumber = index + 2; // Starting from 2 as 1 is used for xTagWebsite
      vcard +=
        `item${itemNumber}.URL:${link.value}\n` +
        `item${itemNumber}.X-ABLabel:${link.name}\n`;
    });

    vcard += `END:VCARD`;

    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    // Create a hidden download link and trigger it
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${contact.name}.vcf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleLinkClick = async (primaryItem) => {
    const linkId = primaryItem.id; // Assuming each item has an ID

    try {
      await axios.post(
        `https://api.x-tag.tech/api/link/${linkId}/visit`
      );
      console.log("API called for link:", linkId);
    } catch (error) {
      console.error("Error calling API for link:", linkId, error);
    }
  };
  const handlePrimaryLinkClick = async (primaryItem) => {
    const linkId = primaryItem.id; // Assuming each item has an ID

    try {
      await axios.post(
        `https://api.x-tag.tech/api/${userData.id}/primary_link/${linkId}/visit`
      );
      console.log("API called for link:", linkId);
    } catch (error) {
      console.error("Error calling API for link:", linkId, error);
    }
  };
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress /> {/* Loading spinner */}
      </div>
    );
  }
  return (
    <>
    <div>
      <div className='relative flex justify-center'
      style={{
            // backgroundImage: `url(${image})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            // height: "100vh",
            position:"absolute",
            width:"100%",
            height:"100%",
            zIndex: 10,
            transition: '0.5s filter linear',
            background:userData?.bgColor,
          
          }}
          >
            
      </div>
      <div className='flex flex-col gap-10 justify-center items-center h-full w-full z-50 absolute'>
        <div className={`relative flex justify-center`}
  style={{
    backgroundImage: `url(${userData?.photo})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "30vh",
    width:"30vh",
    // zIndex: 10,
    transition: '0.5s filter linear',
    margin:"10px",
    borderRadius:"100px",
    // borderColor:userData?.buttonColor,
    border: `4px solid ${userData?.buttonColor}`
  }}
>
<div className='relative w-screen'>
    <div className='w-screen'>
  <div className='absolute h-20 top-12 w-full ' style={{ zIndex: -30 ,background: userData?.buttonColor }}></div>
  </div>
</div>
</div>
        <div>
            <h2 className='text-3xl font-semibold -mt-7 Robote_bold text-center' style={{ color: textcolor }}>
            {userData?.firstName !=="undefined" && userData?.firstName !=="null" &&
            userData?.firstName !==undefined && userData?.firstName !==null? userData?.firstName : ""}{" "}

            {userData?.lastName !=="undefined" && userData?.lastName !=="null" &&
            userData?.lastName !==undefined && userData?.lastName !==null? userData?.lastName : ""}{" "}
            </h2>
            
              {userData?.jobTitle !== null &&
            userData?.jobTitle !== "null" &&
            userData?.jobTitle !== "undefined" &&
            userData?.jobTitle !== undefined ? (
              <h2 className='text-center text-xl Robote' style={{ color: textcolor }}>
                {userData?.jobTitle}
                </h2>
            ) : (
              ""
            )}
            {userData?.businessName !== null &&
              userData?.businessName !== "null" &&
              userData?.businessName !== "undefined" &&
              userData?.businessName !== undefined ? (
                <h2 className='text-center text-md Robote' style={{ color: textcolor }}>
                  {userData?.businessName}
                  </h2>
              ) : (
                ""
              )}

              {userData?.location !== null &&
                userData?.location !== "null" &&
                userData?.location !== "undefined" &&
                userData?.location !== undefined ? (
                  <h2 className='text-center text-md Robote' style={{ color: textcolor }}>
                    {userData?.location}
                    </h2>
                ) : (
                  ""
                )}

                {userData?.bio !== null &&
                  userData?.bio !== "null" &&
                  userData?.bio !== "undefined" &&
                  userData?.bio !== undefined ? (
                    <h2 className='text-center text-md Robote' style={{ color: textcolor }}>
                      {userData?.bio}
                      </h2>
                  ) : (
                    ""
                  )}
             <div className="grid grid-cols-3 justify-center items-center gap-y-7 mt-20 place-items-center pb-20">
          {userData?.primary_links?.map((primaryItem, primaryIndex) => (
            <div
              className="animate-shakeX"
              key={primaryIndex}
              style={{
                width: "70px", // Fixed width for the container
                height: "70px", // Fixed height for the container
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              {userData?.primary_links?.[primaryIndex]?.name !== "Telegram" ? (
                <a
                  href={primaryItem?.value}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    handlePrimaryLinkClick(primaryItem);
                  }}
                >
                  <img
                    src={primaryItem?.logo}
                    alt="logo"
                    style={{
                      width: "100%", // Stretch the logo to fill the container width
                      height: "100%", // Stretch the logo to fill the container height
                      objectFit: "fill", // This will stretch the image to fill the container
                    }}
                  />
                </a>
              ) : (
                <a
                  href={primaryItem?.value}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    handlePrimaryLinkClick(primaryItem);
                  }}
                >
                  <img
                    onClick={() => {
                      const cleanedPhoneNum = userData.phoneNum.replace(
                        /\s+/g,
                        ""
                      ); 
                      const telegramUsername =
                        userData?.primary_links?.[primaryIndex]?.value; // Replace with the actual username or ID
                      window.open(`https://t.me/${telegramUsername}`);
                    }}
                    src={primaryItem?.logo}
                    on
                    alt="logo"
                    style={{
                      width: "100%", // Stretch the logo to fill the container width
                      height: "100%", // Stretch the logo to fill the container height
                      objectFit: "fill", // This will stretch the image to fill the container
                    }}
                  />
                </a>
              )}
            </div>
          ))}

          {userData?.second_links?.map((primaryItem, primaryIndex) => (
            <div key={primaryIndex} className="animate-shakeX">
              <a
                href={primaryItem?.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleLinkClick(primaryItem)}
                className=""
              >
                <button className="flex justify-center items-center">
                  <img src={primaryItem?.logo} className="w-[50px] h-[50px] " />
                </button>
              </a>
            </div>
          ))}
          
        </div>

    
       
  
        </div>
        
      </div>
   









      <button
          onClick={saveToContacts}
          className="fixed z-50 bottom-5 w-36 h-14 bg-[var(--button-bg-color)] rounded-3xl border-2  text-[var(--button-text-color)] font-bold text-sm right-2"
          style={{ border: "2px solid", borderColor: { textbottoncolor } }}
          
        >
          <div
            className="flex flex-row gap-2 justify-center items-center  text-center w-full"
            style={{ color: textbottoncolor }}
          >
            <div className="flex flex-col">
              <span className="text-sm">Add To</span>
              <span className="text-sm">Contacts</span>
            </div>
            <div className="border p-2 rounded-full">
              <FaPlus />
            </div>
          </div>
        </button>

        <button className="fixed z-50 bottom-2 left-1 w-14 h-14 rounded-full flex justify-center items-center text-[var(--button-text-color)]">
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 260,
                        p: 4,
                    }}
                >
                    <div
                        style={{
                            borderRadius: "20px", // Adjust as needed for roundness
                            overflow: "hidden",
                        }}
                    >
                        <QRCode
                            value={urlToShare}
                            size={200}
                            fgColor={textcolor}
                            bgColor={userData?.bgColor}
                            level="M"
                            includeMargin={true}
                            renderAs="svg"
                        />
                    </div>
                </Box>
            </Modal>
            <Box sx={{ height: 1 , transform: 'translateZ(0px)', flexGrow: 1}}  style={{
              bottom: "0px",
              left: "0px",
              whiteSpace: "nowrap",
              zIndex: "0",
              color: `white`,
              
              position:"fixed",
              zIndex:100,
            }}>
    <SpeedDial
    onClick={() => {setOppen(!oppen);setAnimate(!animate)}}
      ariaLabel="SpeedDial basic example"
      
      sx={{ 
        position: 'absolute', 
        bottom: 10, 
        left: 16, 
        zIndex: 40, 
        '& .MuiSpeedDial-fab': {
          backgroundColor: `${userData?.buttonColor} !important`,
          '&:hover': {
            backgroundColor: `${userData?.buttonColor} !important`
                      }
        },
        marginLeft:"-20px"
      }}
     
      style={{color: textbottoncolor}}
      icon={
        <div
          style={{
            border: "2px solid",
            borderColor: textbottoncolor,
            borderRadius: "50%",
            padding: "12px",
          }}
        >
          <PiUploadSimpleBold size={28}       style={{color: textbottoncolor}} />
        </div>
      }
      ref={speedDialRef}
      open={oppen}
    >
          
       <SpeedDialAction
       sx={{
    borderTop: '1px solid grey',
    borderRadius: '8px',
    backgroundColor: userData?.buttonColor,
    padding: '8px',
    marginTop: '-2px', // Reduced margin to make the actions closer
    transition: 'all 0.3s ease-in-out',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: userData?.buttonColor,
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
    },
    width:"15.5vh",
     ...(animate ? { animation: 'slide-from-right 0.7s ease-in-out' } : {}),
  }}
          icon=
          <div className='flex' onClick={handleOpen}>
          <IoQrCodeOutline  style={{color: textbottoncolor}} size={15}  />
           <p
           style={{color: textbottoncolor}}
          className={`text-[8px] pl-2 ${animate ? 'animate-slide-from-right' : ''}`}>my qr code</p>
          </div>
          
        >
        </SpeedDialAction>
        <SpeedDialAction
         sx={{
    borderTop: '1px solid grey',
    borderRadius: '8px',
    backgroundColor: userData?.buttonColor,
    padding: '8px',
    marginTop: '-2px', // Reduced margin to make the actions closer
    transition: 'all 0.3s ease-in-out',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: userData?.buttonColor,
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
    },
    width:"15.5vh",
     ...(animate ? { animation: 'slide-from-right 0.4s ease-in-out' } : {}),
  }}
          icon=
          <div className='flex '    style={{color: textbottoncolor}} onClick={handleCopyLink}>
          <FaRegCopy size={14}  color={userData?.textbottoncolor}/>
          <p   style={{color: textbottoncolor}} className={`text-[9px] pl-2 ${animate ? 'animate-slide-from-right' : ''}`}>copy link</p>
          </div>
          > 
          </SpeedDialAction>
          
          <SpeedDialAction
          sx={{
    borderTop: '1px solid grey',
    borderRadius: '8px',
    backgroundColor: userData?.buttonColor,
    padding: '8px',
    marginTop: '-2px', // Reduced margin to make the actions closer
    transition: 'all 0.3s ease-in-out',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: userData?.buttonColor,
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
    },
    width:"15.5vh",
     ...(animate ? { animation: 'slide-from-right 0.3s ease-in-out' } : {}),
  }}
          icon=
          <div className='flex '  onClick={() => setOppen(!oppen)}>
        
        


          <RWebShare
          data={{
            url: urlToShare,
            title: "Share your X tag link",
          }}
          onClick={() => setOppen(!oppen)}
        >
          <div className="flex -ml-4 -mt-2">
          
            <IoShareSocial size={15} className="w-4 h-4 mt-[10px] ml-2 z-50"color={textbottoncolor}/>

            <p style={{color: textbottoncolor}} className={`text-[9px] pl-2 mt-[10px] ${animate ? 'animate-slide-from-right' : ''}`}>SHARE LINK</p>
          </div>
        </RWebShare>
          </div>
          > 
          </SpeedDialAction>
    
     
    </SpeedDial>
  </Box>


        </button>
        {isVisible && (
          <div className="z-50 fixed bottom-16 left-1/2 transform -translate-x-1/2 border-2 bg-gray-700 text-white rounded-xl px-2">
              link copied
          </div>
      )}

    </div>
  
       
    </>
  )
}

export default TemplateFour